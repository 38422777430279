/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SiteLinks from "../components/site-links"
import { Separator } from "../components/gatsby-sidebar/docs-breadcrumb"
import CopyrightInfo from "../components/copyright-info"

const IndexBlogPage = ({ data }) => {
  const posts = data.allFile.edges

  return (
    <Layout>
      <SEO title="Blog" />
      <div 
        sx={{
          m: `1.45rem auto`,
          maxWidth: 900,
        }}
      >
        <h2>Posts</h2>
        <p>The following are all stubs.  Someday perhaps...</p>
        {posts.map(({ node }) => (
          <div key={node.id}>
            <Link
              to={node.childMarkdownRemark.fields.slug}
              sx={{
                color: `inherit`,
                textDecoration: `none`,
              }}
            >
              <h4>
                {node.childMarkdownRemark.frontmatter.title}
                {``}
                <span sx={{ color: `#bbb`}}>
                  - {node.childMarkdownRemark.frontmatter.date}
                </span>
              </h4>
            </Link>
          </div>
        ))}
      </div>

      <SiteLinks>
        <Link to="/">Landing Page</Link>
        <Separator character={<span>{` `}</span>} />
        <Link to="/index-project/">Projects</Link>
      </SiteLinks>

      <CopyrightInfo />
    </Layout>
  )
}

export default IndexBlogPage

export const query = graphql`
  query BlogIndexPageQuery {
    allFile(
      filter: {
        sourceInstanceName: {eq: "blog"}
      },
      sort: { 
        fields: childMdx___frontmatter___date, 
        order: DESC
      }
    ) {
      edges {
        node {
          id
          relativeDirectory
          childMarkdownRemark {
            frontmatter {
              title
              date(formatString: "DD MMMM YYYY")
            }
            fields {
              slug
            }
          }
        }
      }
    }
  }

`